import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { SectionItem } from '@zep/module/payment/Pricing/SectionItem';
import { cn } from '@zep/utils';
export type PricingCardProps = ComponentPropsWithoutRef<'div'> & {
  top: ReactNode;
  bottom: ReactNode;
  ActionButton: ReactNode;
};
export const PricingCard = (props: PricingCardProps) => {
  const {
    top,
    bottom,
    ActionButton,
    ...rest
  } = props;
  return <SectionItem style={{
    boxShadow: '0 4px 8px 4px rgba(28, 27, 31, 0.04)',
    ...rest.style
  }} {...rest} className={cn(['rounded-[24px] bg-white flex flex-col', 'min-[976px]:w-[420px] min-w-[340px]', props.className])} data-sentry-element="SectionItem" data-sentry-component="PricingCard" data-sentry-source-file="PricingCard.tsx">
      {top}
      {bottom}
      <div className={'flex w-full justify-center p-xl pt-xs'}>
        {ActionButton}
      </div>
    </SectionItem>;
};
export const PricingCardTopLayout = (props: ComponentPropsWithoutRef<'div'>) => {
  const {
    className,
    ...restProps
  } = props;
  return <div {...restProps} className={cn('flex flex-col relative gap-md pt-xl pb-lg px-xl rounded-t-[24px]', 'min-h-[200px]', className)} data-sentry-component="PricingCardTopLayout" data-sentry-source-file="PricingCard.tsx">
      {props.children}
    </div>;
};
export const PricingCardBottomLayout = (props: ComponentPropsWithoutRef<'div'>) => {
  const {
    children,
    ...restProps
  } = props;
  return <div className={'min-h-[146px] grow px-xl py-lg pb-sm'} data-sentry-component="PricingCardBottomLayout" data-sentry-source-file="PricingCard.tsx">
      <div className={'flex flex-col justify-between gap-lg'} {...restProps}>
        {children}
      </div>
    </div>;
};
export const CardBottomContentItem = (props: ComponentPropsWithoutRef<'div'> & {
  icon: ReactNode;
}) => {
  const {
    icon,
    ...rest
  } = props;
  return <div className={'text-body-sm font-medium'} data-sentry-component="CardBottomContentItem" data-sentry-source-file="PricingCard.tsx">
      <div className={'flex items-center gap-[6px] text-body-lg font-medium text-neutral'} {...rest}>
        {icon}
        <div className={'flex items-center gap-xs'}>{props.children}</div>
      </div>
    </div>;
};